/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:16:21
 */
export default {
  label: '模块背景设置',
  type: 'backSetting',
  property: '',
  value: {},
  options: {
    default: { "status": "color", "color": "#f5f5f5", "url": "", "height": "", "zoom": "repeat" },
    note: '',
  }
}
