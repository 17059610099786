/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:15:51
 */
export default {
    label: '数字显示',
    type: 'quantity',
    property: '',
    value: {},
    options: {
        default: { "status": "single", "size": 5 },
        note: '',
    }
}
